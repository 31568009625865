<script setup>
import { onMounted } from "vue";
import { setPageTitle } from "../../utils/pageTitle";
import DamageReports from "@/components/damage-reports/DamageReports.vue";
import PageTitleCard from "@/components/PageTitleCard.vue";
import { useRouter } from "vue-router";

onMounted(() => {
  setPageTitle("Damage Reports");
});

const router = useRouter();
</script>

<template>
  <PageTitleCard justifyCard="space-between">
    <template #actions>
      <div class="d-flex-end ml-auto mr-2">
        <v-btn
          flat
          class="font-weight-bold"
          color="primary"
          @click="router.push('/damage-reports/new')"
        >
          New Damage Report
        </v-btn>
      </div>
    </template>
  </PageTitleCard>
  <div id="damage-grid-wrapper">
    <DamageReports />
  </div>
</template>

<style scoped>
#damage-grid-wrapper {
  height: 100%;
  padding-top: 0.25%;
}
</style>
