<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { ref } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import { useRouter } from "vue-router";
import {
  saveGridState,
  loadGridState,
  resetGridFilterState,
  resetGridColState,
  connectToServerApi,
} from "@/utils/agGridUtils";
import { useThemeStore } from "@/stores/themeStore";
import Warehouses from "@/components/common/Warehouses.vue";
import status from "../../components/schedule/statusRenderer.vue";
import { getDefaultColDefs, getDefaultGridOptions } from "@/utils/aggridCommonConfigs";
import useDamageReports from "@/hooks/useDamageReports";
import { shortDateWithTimeTz } from "@/utils/dateUtils";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const themeStore = useThemeStore();
const router = useRouter();
const GRID_SAVE_KEY = "damage-reports";
const DAMAGE_REPORTS_EXTERNAL_FILTERS_KEY = "damage-reports-external-filter";

const { workflowStateColor } = useDamageReports();

let gridApi;
let context = ref(null);
const DAMAGE_REPORTS_URL = "/damage_reports";

const advancedFilterKeys = [
  "id",
  "workflow_state",
  "damage_type",
  // "order",
  "client.name",
  // "lp.product.name",
  // "lp.product.scc",
  // "qty_damaged",
  "warehouse.name",
  "order_type",
  "created_by.name",
  "created_by.created_at",
];
const onGridReady = (params) => {
  gridApi = params.api;
  context.value = {
    apiUrl: DAMAGE_REPORTS_URL,
    filterKey: DAMAGE_REPORTS_EXTERNAL_FILTERS_KEY,
    advancedFilterKeys: advancedFilterKeys,
  };
  loadGridState(GRID_SAVE_KEY, gridApi, columnDefs);
  gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

const onStateUpdated = function (params) {
  saveGridState(GRID_SAVE_KEY, gridApi);
};

const resetGridFiltersSettings = () => {
  resetGridFilterState(GRID_SAVE_KEY, gridApi);
  router.go();
};

const resetGridColSettings = () => {
  resetGridColState(GRID_SAVE_KEY, gridApi);
  router.go();
};

const warehouseFiltersChangedCallback = function (whIds) {
  gridApi.onFilterChanged();
};

const defaultColDef = getDefaultColDefs();

const getStatusColor = (params) => {
  let color = "grey";
  color = workflowStateColor(params.value);

  return {
    color,
  };
};

const columnDefs = [
  { field: "id", minWidth: 100, headerName: "ID", cellDataType: "text" },
  {
    field: "workflow_state",
    minWidth: 150,
    colId: "workflow_state",
    filter: "agSetColumnFilter",
    headerName: "Status",
    cellDataType: "text",
    cellRenderer: status,
    cellRendererParams: getStatusColor,
    filterParams: {
      values: [
        "new",
        "processing",
        "in_review",
        "reviewing",
        "rejected",
        "approved",
        "destroyed",
      ], // add more values if needed
    },
  },
  { field: "damage_type", minWidth: 200, headerName: "Type", cellDataType: "text" },
  { field: "order", minWidth: 200, headerName: "Order", cellDataType: "text" },
  { field: "client.name", minWidth: 200, headerName: "Customer", cellDataType: "text" },
  {
    field: "lp.product.name",
    minWidth: 350,
    headerName: "Product",
    cellDataType: "text",
  },
  { field: "lp.product.scc", minWidth: 200, headerName: "SCC", cellDataType: "text" },
  {
    field: "qty_damaged",
    minWidth: 150,
    headerName: "Quantity Damaged",
    cellDataType: "text",
  },
  {
    field: "warehouse.name",
    minWidth: 200,
    headerName: "Warehouse",
    cellDataType: "text",
  },
  { field: "order_type", minWidth: 100, headerName: "Order Out", cellDataType: "text" }, // not sure what field to map
  {
    field: "created_by.name",
    minWidth: 200,
    headerName: "Employee Reporting",
    cellDataType: "text",
  },
  {
    field: "created_at",
    minWidth: 210,
    headerName: "Date",
    cellDataType: "text",
    valueGetter: function (params) {
      return shortDateWithTimeTz(params.data.created_at);
    },
  },
];

const rowDoubleClicked = function (event) {
  if (event.data.id) {
    gridApi.showLoadingOverlay();
    window.location = `/damage-reports/${event.data.id}`;
  } else {
    console.log("Row clicked does not have an event.data.id!");
    console.log(event);
  }
};

const gridOptions = getDefaultGridOptions(columnDefs);
</script>

<template>
  <Warehouses
    :callBack="warehouseFiltersChangedCallback"
    :parent-filter="DAMAGE_REPORTS_EXTERNAL_FILTERS_KEY"
  />

  <div class="d-flex justify-end mx-4 w-full mt-4">
    <div class="align-center">
      <v-btn class="" variant="text" @click="resetGridFiltersSettings"
        >Reset Filters</v-btn
      >
      <v-btn class="mr-2" variant="text" @click="resetGridColSettings"
        >Reset Columns</v-btn
      >
    </div>
  </div>
  <AgGridVue
    style="width: 100%; height: 100%"
    :class="themeStore.agTheme"
    :columnDefs="columnDefs"
    :defaultColDef="defaultColDef"
    :gridOptions="gridOptions"
    @state-updated="onStateUpdated"
    @grid-ready="onGridReady"
    @rowDoubleClicked="rowDoubleClicked"
    :context="context"
  ></AgGridVue>
</template>
